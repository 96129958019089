import { TextField } from "@mui/material";
import { getHostName } from "../../api-services/axiosClient";

const StaffSignupInput = ({
  title,
  type,
  handleUpdateParams,
  handleOnBlur,
  fieldType = "text",
  params,
  errors,
  touched,
  isSubDomainInput = false,
}) => {
  return (
    <div className="add-staff__field">
      <div className="add-staff__field-title">{title}</div>
      <div className="add-staff__field-input-container">
        <TextField
          label=""
          id={type}
          name={type}
          value={params[type]}
          type={fieldType}
          size="small"
          error={touched[type] && Boolean(errors[type])}
          helperText={touched[type] && errors[type]}
          className={`add-staff__field-input ${type}`}
          onChange={handleUpdateParams}
          onBlur={handleOnBlur}
        />
        {isSubDomainInput && <span>.app.{getHostName()}</span>}
      </div>
    </div>
  );
};

export default StaffSignupInput;
